@import url("https://fonts.googleapis.com/css2?family=Oswald:wght@300;400;600;700&family=VT323&display=swap");

html,
body,
#root {
  width: 100%;
  min-height: 100%;
  margin: 0;
  padding: 0;
}
